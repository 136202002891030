|<aside #asideElem class="static-menu" id="menu-lat-container" style="overflow: hidden;" (mouseenter)="mouseEnter();">
  <div class="menu-list">
    <ion-item lines="none" color="light-black" class="menu-lat-header-logo"
      style="display: block !important; padding-left: 23px;">
      <img style="width: 15%;" class="logo-emblema" slot="start" src="assets/imgs/logo/csi/Emblema_Color.png" />
      <ion-label class="center-box ion-no-margin">
        <img style="width: 60%;" class="logo-wordmark" slot="start" src="assets/imgs/logo/csi/CSI_Logotipo_Color.png" />
        <!-- <p class="ion-text-left  menu-lat-header-text">Quantum</p> -->
      </ion-label>
      <ion-icon class="hamburguer-icon" name="menu" slot="end" (click)="toggleStaticMenu(true)"></ion-icon>
    </ion-item>
    <div #scrollbarContainer class="scrollbar-container">
      <ion-item class="menu-item quantum-logo" lines="none" (click)="toggleMenu(false)">
        <img class="quantum-logo" slot="start" src="assets/imgs/logo/emblema/Quantum-Emblema-blue.png" />
        <ion-label class="vertical-center-box">
          <img style="width: 70%;" class="" slot="start" src="assets/imgs/logo/wordmark/Quantum-Wordmark-Blue.png" />
        </ion-label>
        <!-- <p>Quantum</p> -->
        <i slot="end" class="arrow" [ngClass]="showQuantumMenu ? 'arrow-down' : 'arrow-right'"></i>
      </ion-item>

      <div class="submenu-list" #submenuElem>
        <ion-item class="menu-item home-item" [color]="activeMenuItem === 'home-item' ? 'tech-blue' : 'light-black'"
          lines="none" [ngClass]="activeMenuItem === 'home-item' ? 'active' : null" (click)="goToHome()"
          *ngIf="showHome">
          <ion-icon slot="start" name="home"></ion-icon>
          <p>{{ 'Menu._Home' | translate }}</p>
        </ion-item>

        <ion-item class="menu-item dasboard-item"
          [color]="activeMenuItem === 'dasboard-item' && (!multipleDashboardActive) ? 'tech-blue' : 'light-black'"
          lines="none" [ngClass]="activeMenuItem === 'dasboard-item' && (!multipleDashboardActive) ? 'active' : null"
          (click)="toggleHomeSubmenu()" *ngIf="showHome">
          <!-- <ion-icon slot="start" name="home"></ion-icon> -->
          <ion-icon slot="start" name="bar-chart-outline"></ion-icon>
          <p>{{ 'Menu.Dashboard._Home' | translate }}</p>
          <i *ngIf="multipleDashboardActive" slot="end" class="arrow"
            [ngClass]="showHomeSubmenu ? 'arrow-down' : 'arrow-right'"></i>
        </ion-item>

        <div class="home-submenu" #homeSubmenuElem [hidden]="!multipleDashboardActive">
          <div>
            <ul>
              <li *ngIf="dashboardFinancieroActive" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="9.5" class="ion-no-padding">
                      <ion-item class="menu-item" lines="none"
                        [color]="activeMenuItem === 'dasboard-item' && dashboardFinancieroActive && currentDashboard === 'financiero' ? 'tech-blue' : 'light-black'"
                        [ngClass]="activeMenuItem === 'dasboard-item' && dashboardFinancieroActive && currentDashboard === 'financiero' ? 'active-item-text' : null"
                        (click)="goToDashboardFinanciero()">
                        <ion-icon slot="start" name="cash-outline"></ion-icon>
                        <p>{{ 'Menu.Dashboard._Financiero' | translate }}</p>
                      </ion-item>
                    </ion-col>
                    <ion-col size="2.5" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item ion-no-padding"
                        [color]="activeMenuItem === 'dasboard-item' && dashboardFinancieroActive && currentDashboard === 'financiero' ? 'tech-blue' : 'light-black'"
                        lines="none"
                        [ngClass]="activeMenuItem === 'dasboard-item' && dashboardFinancieroActive && currentDashboard === 'financiero' ? 'dashboard-active' : null"
                        (click)="selectFavouriteDashboard('financiero')">
                        <ion-icon class="fav-icon" slot="start"
                          [name]="favouriteDashboard === 'financiero' ? 'star' : 'star-outline'">
                        </ion-icon>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="dashboardOperativoActive" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="9.5" class="ion-no-padding">
                      <ion-item class="menu-item" lines="none"
                        [color]="activeMenuItem === 'dasboard-item' && dashboardOperativoActive && currentDashboard === 'operativo' ? 'tech-blue' : 'light-black'"
                        [ngClass]="activeMenuItem === 'dasboard-item' && dashboardOperativoActive && currentDashboard === 'operativo' ? 'active-item-text' : null"
                        (click)="goToDashboardOperativo()">
                        <!-- <ion-icon name="document" slot="start"></ion-icon> -->
                        <ion-icon slot="start" name="car-outline"></ion-icon>
                        <p>{{ 'Menu.Dashboard._Operativo' | translate }}</p>
                      </ion-item>
                    </ion-col>
                    <ion-col size="2.5" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item ion-no-padding"
                        [color]="activeMenuItem === 'dasboard-item' && dashboardOperativoActive && currentDashboard === 'operativo' ? 'tech-blue' : 'light-black'"
                        lines="none"
                        [ngClass]="activeMenuItem === 'dasboard-item' && dashboardOperativoActive && currentDashboard === 'operativo' ? 'dashboard-active' : null"
                        (click)="selectFavouriteDashboard('operativo')">
                        <ion-icon class="fav-icon" slot="start"
                          [name]="favouriteDashboard === 'operativo' ? 'star' : 'star-outline'">
                        </ion-icon>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="dashboardAMDF" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="9.5" class="ion-no-padding">
                      <ion-item class="menu-item" lines="none"
                        [color]="activeMenuItem === 'dasboard-item' && dashboardAMDF && currentDashboard === 'amdf' ? 'tech-blue' : 'light-black'"
                        [ngClass]="activeMenuItem === 'dasboard-item' && dashboardAMDF && currentDashboard === 'amdf' ? 'active-item-text' : null"
                        (click)="goToDashboardAMDF()">
                        <!-- <ion-icon name="bar-chart-outline" slot="start"></ion-icon> -->
                        <ion-icon slot="start" name="bar-chart-outline"></ion-icon>
                        <p>{{ 'Menu.Dashboard._AMDF' | translate }}</p>
                      </ion-item>
                    </ion-col>
                    <ion-col size="2.5" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item ion-no-padding"
                        [color]="activeMenuItem === 'dasboard-item' && dashboardAMDF && currentDashboard === 'amdf' ? 'tech-blue' : 'light-black'"
                        lines="none"
                        [ngClass]="activeMenuItem === 'dasboard-item' && dashboardAMDF && currentDashboard === 'amdf' ? 'dashboard-active' : null"
                        (click)="selectFavouriteDashboard('amdf')">
                        <ion-icon class="fav-icon" slot="start"
                          [name]="favouriteDashboard === 'amdf' ? 'star' : 'star-outline'">
                        </ion-icon>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="dashboardMazda" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="9.5" class="ion-no-padding">
                      <ion-item class="menu-item" lines="none"
                        [color]="activeMenuItem === 'dasboard-item' && dashboardMazda && currentDashboard === 'mazda' ? 'tech-blue' : 'light-black'"
                        [ngClass]="activeMenuItem === 'dasboard-item' && dashboardMazda && currentDashboard === 'mazda' ? 'active-item-text' : null"
                        (click)="goToDashboardMazda()">
                        <!-- <ion-icon name="bar-chart-outline" slot="start"></ion-icon> -->
                        <ion-icon slot="start" name="bar-chart-outline"></ion-icon>
                        <p>{{ 'Menu.Dashboard._Mazda' | translate }}</p>
                      </ion-item>
                    </ion-col>
                    <ion-col size="2.5" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item ion-no-padding"
                        [color]="activeMenuItem === 'dasboard-item' && dashboardMazda && currentDashboard === 'mazda' ? 'tech-blue' : 'light-black'"
                        lines="none"
                        [ngClass]="activeMenuItem === 'dasboard-item' && dashboardMazda && currentDashboard === 'mazda' ? 'dashboard-active' : null"
                        (click)="selectFavouriteDashboard('mazda')">
                        <ion-icon class="fav-icon" slot="start"
                          [name]="favouriteDashboard === 'mazda' ? 'star' : 'star-outline'">
                        </ion-icon>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="dashboardBMW" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="9.5" class="ion-no-padding">
                      <ion-item class="menu-item" lines="none"
                        [color]="activeMenuItem === 'dasboard-item' && dashboardBMW && currentDashboard === 'bmw' ? 'tech-blue' : 'light-black'"
                        [ngClass]="activeMenuItem === 'dasboard-item' && dashboardBMW && currentDashboard === 'bmw' ? 'active-item-text' : null"
                        (click)="goToDashboardBMW()">
                        <!-- <ion-icon name="bar-chart-outline" slot="start"></ion-icon> -->
                        <ion-icon slot="start" name="bar-chart-outline"></ion-icon>
                        <p>{{ 'Menu.Dashboard._BMW' | translate }}</p>
                      </ion-item>
                    </ion-col>
                    <ion-col size="2.5" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item ion-no-padding"
                        [color]="activeMenuItem === 'dasboard-item' && dashboardBMW && currentDashboard === 'bmw' ? 'tech-blue' : 'light-black'"
                        lines="none"
                        [ngClass]="activeMenuItem === 'dasboard-item' && dashboardBMW && currentDashboard === 'bmw' ? 'dashboard-active' : null"
                        (click)="selectFavouriteDashboard('bmw')">
                        <ion-icon class="fav-icon" slot="start"
                          [name]="favouriteDashboard === 'bmw' ? 'star' : 'star-outline'">
                        </ion-icon>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
            </ul>
          </div>
        </div>

        <ion-item class="menu-item dashboard-bsc-item" color="light-black" lines="none"
          (click)="toggleDashboardBSCSubmenu()" *ngIf="showDashboardBSC">
          <!-- <ion-icon slot="start" name="stats-chart-outline"></ion-icon> -->
          <ion-icon slot="start" name="file-tray-full-outline"></ion-icon>
          <p>{{ 'Menu.BSC._Home' | translate }}</p>
          <i slot="end" class="arrow" [ngClass]="showDashboardBSCSubmenu ? 'arrow-down' : 'arrow-right'"></i>
        </ion-item>

        <div class="home-submenu" #dashboardBSCSubmenuElem>
          <div>
            <ul>
              <li *ngIf="dashboardBSC_CPVActive" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="(activeMenuItem === 'dashboard-bsc-item') && dashboardBSC_CPVActive && (currentDashboardBSC === 'cpv') ? 'tech-blue' : 'light-black'"
                        [ngClass]="(activeMenuItem === 'dashboard-bsc-item') && dashboardBSC_CPVActive && (currentDashboardBSC === 'cpv') ? 'active' : null"
                        (click)="goToDashboardBSCCPV()">
                        <!-- <ion-icon name="stats-chart" slot="start"></ion-icon> -->
                        <ion-icon slot="start" name="file-tray-full-outline"></ion-icon>
                        <p>{{ 'Menu.BSC._CPV' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="dashboardBSC_CSIActive" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="(activeMenuItem === 'dashboard-bsc-item') && dashboardBSC_CSIActive && (currentDashboardBSC === 'csi') ? 'tech-blue' : 'light-black'"
                        [ngClass]="(activeMenuItem === 'dashboard-bsc-item') && dashboardBSC_CSIActive && (currentDashboardBSC === 'csi') ? 'active' : null"
                        (click)="goToDashboardBSCCSI()">
                        <!-- <ion-icon name="stats-chart" slot="start"></ion-icon> -->
                        <ion-icon slot="start" name="file-tray-full-outline"></ion-icon>
                        <p>{{ 'Menu.BSC._CSI' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="dashboardBSC_CSIDActive" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="(activeMenuItem === 'dashboard-bsc-item') && dashboardBSC_CSIDActive && (currentDashboardBSC === 'csid') ? 'tech-blue' : 'light-black'"
                        [ngClass]="(activeMenuItem === 'dashboard-bsc-item') && dashboardBSC_CSIDActive && (currentDashboardBSC === 'csid') ? 'active' : null"
                        (click)="goToDashboardBSCCSID()">
                        <!-- <ion-icon name="stats-chart" slot="start"></ion-icon> -->
                        <ion-icon slot="start" name="file-tray-full-outline"></ion-icon>
                        <p>{{ 'Menu.BSC._CSID' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="dashboardBSC_CSIXActive" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="(activeMenuItem === 'dashboard-bsc-item') && dashboardBSC_CSIXActive && (currentDashboardBSC === 'csix') ? 'tech-blue' : 'light-black'"
                        [ngClass]="(activeMenuItem === 'dashboard-bsc-item') && dashboardBSC_CSIXActive && (currentDashboardBSC === 'csix') ? 'active' : null"
                        (click)="goToDashboardBSCCSIX()">
                        <!-- <ion-icon name="stats-chart" slot="start"></ion-icon> -->
                        <ion-icon slot="start" name="file-tray-full-outline"></ion-icon>
                        <p>{{ 'Menu.BSC._CSIX' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="dashboardBSC_SunusActive" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="(activeMenuItem === 'dashboard-bsc-item') && dashboardBSC_SunusActive && (currentDashboardBSC === 'sunus') ? 'tech-blue' : 'light-black'"
                        [ngClass]="(activeMenuItem === 'dashboard-bsc-item') && dashboardBSC_SunusActive && (currentDashboardBSC === 'sunus') ? 'active' : null"
                        (click)="goToDashboardBSCSunus()">
                        <!-- <ion-icon name="stats-chart" slot="start"></ion-icon> -->
                        <ion-icon slot="start" name="file-tray-full-outline"></ion-icon>
                        <p>{{ 'Menu.BSC._SUNUS' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
            </ul>
          </div>
        </div>

        <ion-item class="menu-item investor-relations-item"
          [color]="activeMenuItem === 'investor-relations-item' ? 'tech-blue' : 'light-black'" lines="none"
          [ngClass]="activeMenuItem === 'investor-relations-item' ? 'active' : null"
          (click)="goToInvestorRelationsPage()" *ngIf="showInvestorRelations">
          <ion-icon slot="start" name="folder-outline"></ion-icon>
          <p>{{ 'Menu._Investor' | translate }}</p>
        </ion-item>

        <ion-item class="menu-item tracking-map-item"
          [color]="activeMenuItem === 'tracking-map-item' ? 'tech-blue' : 'light-black'" lines="none"
          [ngClass]="activeMenuItem === 'tracking-map-item' ? 'active' : null" (click)="goToTrackingMap()"
          *ngIf="showTrackingMap">
          <ion-icon slot="start" name="location"></ion-icon>
          <p>{{ 'Menu._Rastreo' | translate }}</p>
        </ion-item>

        <ion-item class="menu-item travel-list-item"
          [color]="activeMenuItem === 'travel-list-item' ? 'tech-blue' : 'light-black'" lines="none"
          [ngClass]="activeMenuItem === 'travel-list-item' ? 'active' : null" (click)="goToTravelList()"
          *ngIf="showTravelList">
          <ion-icon slot="start" name="list"></ion-icon>
          <p>{{ 'Menu._Viajes' | translate }}</p>
        </ion-item>

        <!-- <ion-item class="menu-item vessel-travel-list-item"
          [color]="activeMenuItem === 'vessel-travel-list-item' ? 'tech-blue' : 'light-black'" lines="none"
          [ngClass]="activeMenuItem === 'vessel-travel-list-item' ? 'active' : null" (click)="goToVesselTravelList()"
          *ngIf="showVesselTravelList">
          <ion-icon slot="start" name="list"></ion-icon>
          <p>{{ 'Menu._Viajes_Buque' | translate }}</p>
        </ion-item> -->

        <ion-item class="menu-item ratings-item"
          [color]="activeMenuItem === 'ratings-item' ? 'tech-blue' : 'light-black'" lines="none"
          [ngClass]="activeMenuItem === 'ratings-item' ? 'active' : null" (click)="goToScore()" *ngIf="showScore">
          <ion-icon slot="start" src="assets/imgs/icons/star.svg"></ion-icon>
          <p>{{ 'Menu._Ratings' | translate }}</p>
        </ion-item>

        <!-- <button ion-item lines="none" (click)="goToClaims()">
          <ion-icon name="csi-reclamo" slot="start"></ion-icon>
          <p>Reclamos</p>
        </button> -->

        <!-- <button ion-item lines="none" (click)="gotToConsultaCD()" *ngIf="showConsultaCD">
          <ion-icon slot="start" style="width:30px; height:20px; margin-bottom: 5% !important;">
            <img src="assets/imgs/factory_white_transparent.png" style="margin-bottom: 10px;">
          </ion-icon>
          <p>Consulta CD</p>
        </button> -->

        <ion-item class="menu-item exchange-item"
          [color]="activeMenuItem === 'exchange-item' ? 'tech-blue' : 'light-black'" lines="none"
          [ngClass]="activeMenuItem === 'exchange-item' ? 'active' : null" (click)="goToExchange()"
          *ngIf="showExchange">
          <ion-icon name="repeat-sharp" slot="start"></ion-icon>
          <p>{{ 'Menu._Intercambios' | translate }}</p>
        </ion-item>

        <ion-item class="menu-item shipment-request-item"
          [color]="activeMenuItem === 'shipment-request-item' ? 'tech-blue' : 'light-black'" lines="none"
          [ngClass]="activeMenuItem === 'shipment-request-item' ? 'active' : null" (click)="goToShipmentRequest()"
          *ngIf="showShipmentRequest">
          <ion-icon name="car" slot="start"></ion-icon>
          <p>{{ 'Menu._Embarque' | translate }}</p>
        </ion-item>

        <ion-item class="menu-item report-list-item"
          [color]="activeMenuItem === 'report-list-item' ? 'tech-blue' : 'light-black'" lines="none"
          [ngClass]="activeMenuItem === 'report-list-item' ? 'active' : null" (click)="goToReports()"
          *ngIf="showReport">
          <ion-icon slot="start" name="document"></ion-icon>
          <p>{{ 'Menu._Reportes' | translate }}</p>
        </ion-item>

        <!--cambio its-->
        <ion-item class="menu-item consulta-cd-item"
          [color]="activeMenuItem === 'consulta-cd-item' ? 'tech-blue' : 'light-black'" lines="none"
          [ngClass]="activeMenuItem === 'consulta-cd-item' ? 'active' : null" (click)="goToConsultBySiteMexico()"
          *ngIf="showConsultBySiteMexico">
          <ion-icon slot="start" name="business-outline"></ion-icon>
          <p>{{ 'Menu._Consulta_CD' | translate }}</p>
        </ion-item>

        <!-- <ion-item class="menu-item"
          [color]="activeMenuItem === 'investor-relations-item' ? 'tech-blue' : 'light-black'" lines="none"
          (click)="goToConsultByDealer()" *ngIf="showConsultByDealer">
          <ion-icon name="podium" slot="start"></ion-icon>
          <p>Consulta Distribuidor</p>
        </ion-item> -->

        <ion-item class="menu-item consulta-vin-item"
          [color]="activeMenuItem === 'consulta-vin-item' ? 'tech-blue' : 'light-black'" lines="none"
          [ngClass]="activeMenuItem === 'consulta-vin-item' ? 'active' : null" (click)="goToMultipleVinConsult()"
          *ngIf="showMultipleVinConsult">
          <ion-icon slot="start" name="search"></ion-icon>
          <p>{{ 'Menu._Consulta_VIN' | translate }}</p>
        </ion-item>

        <ion-item class="menu-item assets-manager-item"
          [color]="activeMenuItem === 'assets-manager-item' ? 'tech-blue' : 'light-black'" lines="none"
          [ngClass]="activeMenuItem === 'assets-manager-item' ? 'active' : null" (click)="goToAssetsManagerPage()"
          *ngIf="showAssetsManager">
          <ion-icon slot="start" name="bag-outline"></ion-icon>
          <p>{{ 'Menu._Assets' | translate }}</p>
        </ion-item>

        <ion-item class="menu-item matriz-estatus-item"
          [color]="activeMenuItem === 'matriz-estatus-item' ? 'tech-blue' : 'light-black'" lines="none"
          [ngClass]="activeMenuItem === 'matriz-estatus-item' ? 'active' : null" (click)="goToMatrizEstatusEquipo()"
          *ngIf="showMatrizEstatusEquipo">
          <ion-icon slot="start" name="bus"></ion-icon>
          <p>{{ 'Menu._Matriz' | translate }}</p>
        </ion-item>

        <ion-item class="menu-item programacion-carga-item"
          [color]="activeMenuItem === 'programacion-carga-item' ? 'tech-blue' : 'light-black'" lines="none"
          [ngClass]="activeMenuItem === 'programacion-carga-item' ? 'active' : null" (click)="goToProgramacionCarga()"
          *ngIf="showProgramacionCarga">
          <ion-icon slot="start" name="compass"></ion-icon>
          <p>{{ 'Menu._Posicionamiento' | translate }}</p>
        </ion-item>

        <ion-item class="menu-item alarmas-item"
          [color]="activeMenuItem === 'alarmas-item' ? 'tech-blue' : 'light-black'" lines="none"
          [ngClass]="activeMenuItem === 'alarmas-item' ? 'active' : null" (click)="goToAlertasPage()"
          *ngIf="showAlertasPage">
          <ion-icon slot="start" name="alert"></ion-icon>
          <p>{{ 'Menu._Alertas' | translate }}</p>
        </ion-item>

        <ion-item class="menu-item consulta-tractor-item"
          [color]="activeMenuItem === 'consulta-tractor-item' ? 'tech-blue' : 'light-black'" lines="none"
          [ngClass]="activeMenuItem === 'consulta-tractor-item' ? 'active' : null" (click)="goToConsultaDeTractor()"
          *ngIf="showConsultaDeTractor">
          <ion-icon slot="start" name="search"></ion-icon>
          <p>{{ 'Menu._Tractor' | translate }}</p>
        </ion-item>

        <ion-item class="menu-item rate-trip-item"
          [color]="activeMenuItem === 'rate-trip-item' ? 'tech-blue' : 'light-black'" lines="none"
          [ngClass]="activeMenuItem === 'rate-trip-item' ? 'active' : null" (click)="goToRateTrip()"
          *ngIf="showRateTrip">
          <ion-icon slot="start" name="star"></ion-icon>
          <p>{{ 'Titulo._Calificar_Viaje' | translate }}</p>
        </ion-item>

        <ion-item class="menu-item permisos-item"
          [color]="activeMenuItem === 'permisos-item' ? 'tech-blue' : 'light-black'" lines="none"
          [ngClass]="activeMenuItem === 'permisos-item' ? 'active' : null" (click)="goToUserPermissions()"
          *ngIf="showPermissions">
          <ion-icon slot="start" name="people-circle"></ion-icon>
          <p>{{ 'Menu._Permisos' | translate }}</p>
        </ion-item>

        <ion-item class="menu-item configuracion-item"
          [color]="activeMenuItem === 'configuracion-item' ? 'tech-blue' : 'light-black'" lines="none"
          [ngClass]="activeMenuItem === 'configuracion-item' ? 'active' : null" (click)="goToConfig()"
          *ngIf="showConfig">
          <ion-icon slot="start" name="settings"></ion-icon>
          <p>{{ 'Menu._Configuración' | translate }}</p>
        </ion-item>

        <ion-item class="menu-item show-more-item" color="light-black" lines="none" (click)="toggleShowMoreSubmenu()"
          *ngIf="showMoreMenu">
          <ion-icon slot="start" name="ellipsis-vertical-outline"></ion-icon>
          <p *ngIf="!showMoreMenuDetails">{{ 'Menu._Show_More' | translate }}</p>
          <p *ngIf="showMoreMenuDetails">{{ 'Menu._Show_Less' | translate }}</p>
          <i slot="end" class="arrow" [ngClass]="showMoreMenuDetails ? 'arrow-down' : 'arrow-right'"></i>
        </ion-item>

        <div class="more-submenu" #moreMenuSubmenuElem>
          <div>
            <ul>
              <!-- <li *ngIf="dashboardBSC_CPVActive_Collapsible" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="(activeMenuItem === 'dashboard-bsc-item') && dashboardBSC_CPVActive_Collapsible && (currentDashboardBSC === 'cpv') ? 'tech-blue' : 'light-black'"
                        [ngClass]="(activeMenuItem === 'dashboard-bsc-item') && dashboardBSC_CPVActive_Collapsible && (currentDashboardBSC === 'cpv') ? 'active' : null"
                        (click)="goToDashboardBSCCPV()">
                        <ion-icon slot="start" name="file-tray-full-outline"></ion-icon>
                        <p>{{ 'Menu.BSC._CPV' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="dashboardBSC_CSIActive_Collapsible" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="(activeMenuItem === 'dashboard-bsc-item') && dashboardBSC_CSIActive_Collapsible && (currentDashboardBSC === 'csi') ? 'tech-blue' : 'light-black'"
                        [ngClass]="(activeMenuItem === 'dashboard-bsc-item') && dashboardBSC_CSIActive_Collapsible && (currentDashboardBSC === 'csi') ? 'active' : null"
                        (click)="goToDashboardBSCCSI()">
                        <ion-icon slot="start" name="file-tray-full-outline"></ion-icon>
                        <p>{{ 'Menu.BSC._CSI' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="dashboardBSC_CSIDActive_Collapsible" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="(activeMenuItem === 'dashboard-bsc-item') && dashboardBSC_CSIDActive_Collapsible && (currentDashboardBSC === 'csid') ? 'tech-blue' : 'light-black'"
                        [ngClass]="(activeMenuItem === 'dashboard-bsc-item') && dashboardBSC_CSIDActive_Collapsible && (currentDashboardBSC === 'csid') ? 'active' : null"
                        (click)="goToDashboardBSCCSID()">
                        <ion-icon slot="start" name="file-tray-full-outline"></ion-icon>
                        <p>{{ 'Menu.BSC._CSID' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="dashboardBSC_CSIXActive_Collapsible" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="(activeMenuItem === 'dashboard-bsc-item') && dashboardBSC_CSIXActive_Collapsible && (currentDashboardBSC === 'csix') ? 'tech-blue' : 'light-black'"
                        [ngClass]="(activeMenuItem === 'dashboard-bsc-item') && dashboardBSC_CSIXActive_Collapsible && (currentDashboardBSC === 'csix') ? 'active' : null"
                        (click)="goToDashboardBSCCSIX()">
                        <ion-icon slot="start" name="file-tray-full-outline"></ion-icon>
                        <p>{{ 'Menu.BSC._CSIX' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="dashboardBSC_SunusActive_Collapsible" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="(activeMenuItem === 'dashboard-bsc-item') && dashboardBSC_SunusActive_Collapsible && (currentDashboardBSC === 'sunus') ? 'tech-blue' : 'light-black'"
                        [ngClass]="(activeMenuItem === 'dashboard-bsc-item') && dashboardBSC_SunusActive_Collapsible && (currentDashboardBSC === 'sunus') ? 'active' : null"
                        (click)="goToDashboardBSCSunus()">
                        <ion-icon slot="start" name="file-tray-full-outline"></ion-icon>
                        <p>{{ 'Menu.BSC._SUNUS' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li> -->
              <li *ngIf="showInvestorRelations_Collapsible" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="activeMenuItem === 'investor-relations-item' ? 'tech-blue' : 'light-black'"
                        [ngClass]="activeMenuItem === 'investor-relations-item' ? 'active' : null"
                        (click)="goToInvestorRelationsPage()">
                        <ion-icon slot="start" name="folder-outline"></ion-icon>
                        <p>{{ 'Menu._Investor' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="showTrackingMap_Collapsible" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="activeMenuItem === 'tracking-map-item' ? 'tech-blue' : 'light-black'"
                        [ngClass]="activeMenuItem === 'tracking-map-item' ? 'active' : null"
                        (click)="goToTrackingMap()">
                        <ion-icon slot="start" name="location"></ion-icon>
                        <p>{{ 'Menu._Rastreo' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="showTravelList_Collapsible" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="activeMenuItem === 'travel-list-item' ? 'tech-blue' : 'light-black'"
                        [ngClass]="activeMenuItem === 'travel-list-item' ? 'active' : null" (click)="goToTravelList()">
                        <ion-icon slot="start" name="list"></ion-icon>
                        <p>{{ 'Menu._Viajes' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="showScore_Collapsible" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="activeMenuItem === 'ratings-item' ? 'tech-blue' : 'light-black'"
                        [ngClass]="activeMenuItem === 'ratings-item' ? 'active' : null" (click)="goToScore()">
                        <ion-icon slot="start" src="assets/imgs/icons/star.svg"></ion-icon>
                        <p>{{ 'Menu._Ratings' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="showExchange_Collapsible" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="activeMenuItem === 'exchange-item' ? 'tech-blue' : 'light-black'"
                        [ngClass]="activeMenuItem === 'exchange-item' ? 'active' : null" (click)="goToExchange()">
                        <ion-icon name="repeat-sharp" slot="start"></ion-icon>
                        <p>{{ 'Menu._Intercambios' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="showShipmentRequest_Collapsible" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="activeMenuItem === 'shipment-request-item' ? 'tech-blue' : 'light-black'"
                        [ngClass]="activeMenuItem === 'shipment-request-item' ? 'active' : null" (click)="goToShipmentRequest()">
                        <ion-icon name="car" slot="start"></ion-icon>
                        <p>{{ 'Menu._Embarque' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="showReport_Collapsible" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="activeMenuItem === 'report-list-item' ? 'tech-blue' : 'light-black'"
                        [ngClass]="activeMenuItem === 'report-list-item' ? 'active' : null" (click)="goToReports()">
                        <ion-icon slot="start" name="document"></ion-icon>
                        <p>{{ 'Menu._Reportes' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="showConsultBySiteMexico_Collapsible" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="activeMenuItem === 'consulta-cd-item' ? 'tech-blue' : 'light-black'"
                        [ngClass]="activeMenuItem === 'consulta-cd-item' ? 'active' : null"
                        (click)="goToConsultBySiteMexico()">
                        <ion-icon slot="start" name="business-outline"></ion-icon>
                        <p>{{ 'Menu._Consulta_CD' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="showMultipleVinConsult_Collapsible" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="activeMenuItem === 'consulta-vin-item' ? 'tech-blue' : 'light-black'"
                        [ngClass]="activeMenuItem === 'consulta-vin-item' ? 'active' : null"
                        (click)="goToMultipleVinConsult()">
                        <ion-icon slot="start" name="search"></ion-icon>
                        <p>{{ 'Menu._Consulta_VIN' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="showAssetsManager_Collapsible" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="activeMenuItem === 'assets-manager-item' ? 'tech-blue' : 'light-black'"
                        [ngClass]="activeMenuItem === 'assets-manager-item' ? 'active' : null"
                        (click)="goToAssetsManagerPage()">
                        <ion-icon slot="start" name="bag-outline"></ion-icon>
                        <p>{{ 'Menu._Assets' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="showMatrizEstatusEquipo_Collapsible" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="activeMenuItem === 'matriz-estatus-item' ? 'tech-blue' : 'light-black'"
                        [ngClass]="activeMenuItem === 'matriz-estatus-item' ? 'active' : null"
                        (click)="goToMatrizEstatusEquipo()">
                        <ion-icon slot="start" name="bus"></ion-icon>
                        <p>{{ 'Menu._Matriz' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="showProgramacionCarga_Collapsible" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="activeMenuItem === 'programacion-carga-item' ? 'tech-blue' : 'light-black'"
                        [ngClass]="activeMenuItem === 'programacion-carga-item' ? 'active' : null"
                        (click)="goToProgramacionCarga()">
                        <ion-icon slot="start" name="compass"></ion-icon>
                        <p>{{ 'Menu._Posicionamiento' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="showAlertasPage_Collapsible" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="activeMenuItem === 'alarmas-item' ? 'tech-blue' : 'light-black'"
                        [ngClass]="activeMenuItem === 'alarmas-item' ? 'active' : null" (click)="goToAlertasPage()">
                        <ion-icon slot="start" name="alert"></ion-icon>
                        <p>{{ 'Menu._Alertas' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="showConsultaDeTractor_Collapsible" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">
                      <ion-item class="menu-item" lines="none"
                        [color]="activeMenuItem === 'consulta-tractor-item' ? 'tech-blue' : 'light-black'"
                        [ngClass]="activeMenuItem === 'consulta-tractor-item' ? 'active' : null"
                        (click)="goToConsultaDeTractor()">
                        <ion-icon slot="start" name="search"></ion-icon>
                        <p>{{ 'Menu._Tractor' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
              <li *ngIf="showRateTrip_Collapsible" class="ion-color-bg-light-black">
                <ion-grid class="bg-grid ion-no-padding">
                  <ion-row>
                    <ion-col size="12" class="ion-no-padding ion-color-bg-light-black">

                      <ion-item class="menu-item" lines="none"
                        [color]="activeMenuItem === 'rate-trip-item' ? 'tech-blue' : 'light-black'"
                        [ngClass]="activeMenuItem === 'rate-trip-item' ? 'active' : null" (click)="goToRateTrip()">
                        <ion-icon slot="start" name="star"></ion-icon>
                        <p>{{ 'Titulo._Calificar_Viaje' | translate }}</p>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </li>
            </ul>
          </div>
        </div>
        <!--fin cambio its-->
      </div>

      <!-- Sub Menu de Quantum Drive -->
      <ion-item *ngIf="showDrive" class="menu-item quantum-logo" lines="none" (click)="goToWebFrame()">
        <img class="quantum-logo" slot="start"
          src="assets/imgs/logo/wordmark/Quantum-Drive/Emblema/Quantum-Drive-Emblema-white.png" />
        <!-- <ion-label class="vertical-center-box">
          <img style="width: 70%;" class="" slot="start" src="assets/imgs/logo/wordmark/Quantum-Drive/logo.png" />
        </ion-label> -->
        <p class="lb-logo-wordmark">Drive</p>

        <!-- <i slot="end" class="arrow" [ngClass]="showQuantumMenu ? 'arrow-down' : 'arrow-right'"></i> -->
      </ion-item>

      <!-- Sub Menu de Quantum Fleet -->
      <ion-item *ngIf="showFleet" class="menu-item quantum-logo" lines="none" (click)="goToWebFrame()">
        <img class="quantum-logo" slot="start"
          src="assets/imgs/logo/wordmark/Quantum-Fleet/Emblema/Quantum-Fleet-Emblema-white.png" />
        <!--         <ion-label class="vertical-center-box">
          <img style="width: 70%;" class="" slot="start" src="assets/imgs/logo/wordmark/Quantum-Fleet/logo.png" />
        </ion-label> -->
        <p class="lb-logo-wordmark">Fleet</p>

        <!-- <i slot="end" class="arrow" [ngClass]="showQuantumMenu ? 'arrow-down' : 'arrow-right'"></i> -->
      </ion-item>

      <!-- Sub Menu de Quantum Ontrack -->
      <ion-item *ngIf="showOntrack" class="menu-item quantum-logo" lines="none" (click)="toggleAppsMenu()">
        <img class="quantum-logo" slot="start"
          src="assets/imgs/logo/wordmark/Quantum-Ontrack/Emblema/Quantum-Ontrack-Emblema-white.png" />
        <!-- <ion-label class="vertical-center-box">
          <img style="width: 70%;" class="" slot="start" src="assets/imgs/logo/wordmark/Quantum-Ontrack/logo.png" />
        </ion-label> -->
        <p class="lb-logo-wordmark">Ontrack</p>

        <i slot="end" class="arrow" [ngClass]="showOntrackMenu ? 'arrow-down' : 'arrow-right'"></i>
      </ion-item>

      <div class="submenu-list" #submenuOntrackElem>
        <ion-item class="menu-item home-item" [color]="activeMenuItem === 'home-item' ? 'tech-blue' : 'light-black'"
          lines="none" [ngClass]="activeMenuItem === 'home-item' ? 'active' : null" (click)="goToiFramePage()"
          *ngIf="showHome">
          <ion-icon slot="start" name="home"></ion-icon>
          <p>{{ 'Menu._Home' | translate }}</p>
        </ion-item>
      </div>

      <!-- Sub Menu de Quantum Yard -->
      <ion-item *ngIf="showYard" class="menu-item quantum-logo" lines="none" (click)="goToWebFrame()">
        <img class="quantum-logo" slot="start"
          src="assets/imgs/logo/wordmark/Quantum-Yard/Emblema/Quantum-Yard-Emblema-white.png" />
        <!-- <ion-label class="vertical-center-box">
          <img style="width: 70%;" class="" slot="start" src="assets/imgs/logo/wordmark/Quantum-Yard/logo.png" />
        </ion-label> -->
        <p class="lb-logo-wordmark">Yard</p>

        <!-- <i slot="end" class="arrow" [ngClass]="showQuantumMenu ? 'arrow-down' : 'arrow-right'"></i> -->
      </ion-item>

      <ng-container *ngFor="let civarItem of civarMenu; let idx = index;">
        <ion-item class="menu-item" lines="none" (click)="toggleMenu(true, idx)">
          <ion-icon slot="start" [name]="getIconNameCivarMenu(civarItem.Cve_Desc_Menu)"></ion-icon>
          <p>{{civarItem.Cve_Desc_Menu}}</p>
          <i slot="end" class="arrow" [ngClass]="showCivarMenu[idx] ? 'arrow-down' : 'arrow-right'"></i>
        </ion-item>
        <div class="civar-menu" #civarElem>
          <my-menu [items]="civarItem.OpcionMenu || []" [level]="1" (onSubmenuOpen)="resizeContainer($event, idx);">
          </my-menu>
        </div>
      </ng-container>

      <ion-item class="menu-item" lines="none" (click)="logout()">
        <ion-icon slot="start" name="log-out-outline"></ion-icon>
        <p>{{ 'Menu._Log_Out' | translate }}</p>
      </ion-item>
      <ion-item color="light-black" lines="none" style="height: 70px !important;"></ion-item>
    </div>
  </div>
</aside>